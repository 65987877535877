<template>
  <!-- v-if="exclusiveDays(data?.exp_date) > 0" -->
  <CardBox customClass=" rounded-2xl  w-full !px-0 !py-0 items-center">
    <!-- <CardBox customClass=" rounded-2xl  w-full !px-0 !py-0 items-center"> -->
    <div
      class="space-y-2 pl-3 sm:pl-4 -bg-blue-300 sm:px-6 w-full sm:pt-3 pt-1"
    >
      <div class="flex justify-between items-center" id="activeTradeElement2">
        <p class="font-bold text-[16px] dark:text-white">
          {{ data?.reference_name ?? "Trade 1" }}
        </p>
        <div
          class="cursor-pointer hover:bg-gray-200 hover:dark:bg-gray-600 h-fit rounded-full p-2"
        >
          <div @click="routeToData(data?.id)" class="cursor-pointer" v-if="Svg">
            <img src="../assets/svgs/clock.svg" />
          </div>
          <img v-else src="../assets/svgs/cross.svg" @click="onCloseModal" />
        </div>
      </div>
      <div class="">
        <div class="flex items-center space-x-2">
          <img
            src="../assets/svgs/calls.svg"
            :class="`${!Svg ? 'w-4 h-4' : ''}`"
          />
          <div class="flex dark:text-white">
            <p :class="`${!Svg ? 'text-[9px]' : 'text-[12px]'}`">$</p>
            <p :class="`font-bold ${!Svg ? 'text-[14px]' : 'text-[18px]'}`">
              {{ data.call_strike?.toLocaleString("en-US") }}
            </p>
          </div>
        </div>
        <p
          :class="`text-[#9E9E9E] dark:text-white/70 ${
            !Svg ? 'text-[10px]' : 'text-[14px]'
          } pl-8`"
        >
          CALL
        </p>
      </div>

      <div class="">
        <div class="flex items-center space-x-2">
          <img
            src="../assets/svgs/puts.svg"
            :class="`${!Svg ? 'w-4 h-4' : ''}`"
          />
          <div class="flex dark:text-white">
            <p class="text-[12px]">$</p>
            <p :class="`font-bold ${!Svg ? 'text-[14px]' : 'text-[18px]'}`">
              {{ data?.put_strike?.toLocaleString("en-US") }}
            </p>
          </div>
        </div>
        <p
          :class="`text-[#9E9E9E] dark:text-white/70 ${
            !Svg ? 'text-[10px]' : 'text-[14px]'
          } pl-8`"
        >
          PUT
        </p>
      </div>

      <div class="">
        <div class="flex items-center space-x-2">
          <img src="../assets/svgs/alaram.svg" />
          <div class="flex">
            <p
              :class="`font-bold text-[#FD3232] ${
                !Svg ? 'text-[12px]' : 'text-[15px]'
              } `"
            >
              <!-- {{ data.days }}Days Remaining -->
              {{ exclusiveDays(data?.exp_date) }} Days Remaining
            </p>
          </div>
        </div>
        <p
          :class="`text-[#9E9E9E] dark:text-white/70  ${
            !Svg ? 'text-[10px]' : 'text-[12px]'
          } pl-7`"
        >
          {{ formattedDate }}
        </p>
      </div>

      <div class="flex justify-between items-center">
        <div class="">
          <div class="flex items-center space-x-2">
            <img src="../assets/svgs/line.svg" />
            <div class="flex">
              <p
                :class="`font-bold ${!Svg ? 'text-[12px]' : 'text-[15px]'}  `"
                :style="{ color: `${arrowColor}` }"
              >
                {{ moveImage(data?.trade_mgmt_number) }}%
              </p>
            </div>
          </div>
          <p
            :class="`text-[#9E9E9E] dark:text-white/70 ${
              !Svg ? 'text-[10px]' : 'text-[12px]'
            } pl-7`"
          >
            Trade Risk Factor
          </p>
        </div>
        <div
          class="cursor-pointer hover:bg-gray-200 hover:dark:bg-gray-600 h-fit rounded-full p-2"
          @click="openModal(data)"
        >
          <img src="../assets/svgs/trash.svg" v-if="Svg" class="h-5 w-5" />
        </div>
      </div>
    </div>
    <div></div>
    <div class="w-full">
      <div class="flex flex-wrap w-[90%]" style="" v-if="Svg">
        <div
          class="move-image -mb-2"
          :style="{ left: `${angleToPercentage(data?.trade_mgmt_number)}%` }"
        >
          <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_340_11789)">
              <path
                d="M19.5896 25.7555C19.3241 26.021 18.971 26.1804 18.5963 26.204C18.2216 26.2276 17.8512 26.1136 17.5546 25.8835L17.4097 25.7555L8.15969 16.5055L8.03173 16.3606L7.94849 16.2419L7.86524 16.0939L7.83903 16.0384L7.7974 15.9351L7.74807 15.7686L7.73265 15.6869L7.71724 15.5944L7.71107 15.5065L7.70799 15.4156L7.71107 15.3246L7.71878 15.2352L7.73265 15.1427L7.74807 15.0625L7.7974 14.896L7.83903 14.7927L7.94694 14.5892L8.04715 14.4505L8.15969 14.3256L8.30461 14.1977L8.42332 14.1144L8.57132 14.0312L8.62682 14.005L8.73011 13.9633L8.89661 13.914L8.97832 13.8986L9.07082 13.8832L9.15869 13.877L9.24965 13.8739L27.7497 13.8739C29.0632 13.8739 29.7492 15.394 28.9568 16.376L28.8396 16.5055L19.5896 25.7555Z"
                :fill="`${arrowColor}`"
              />
            </g>
            <defs>
              <clipPath id="clip0_340_11789">
                <rect
                  width="37"
                  height="37"
                  fill="white"
                  transform="translate(37 37) rotate(180)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div
        :class="`flex w-100% rounded-b-3xl  progressLeft progressRight  justify-between ${
          !Svg ? 'mt-5' : ''
        }`"
      >
        <div
          :class="data?.trade_mgmt_number <= 10 ? 'w-[25%]' : 'w-[15%]'"
          class="h-3 progressLeft bg-[#3EAF3F]"
        />
        <div
          :class="
            data?.trade_mgmt_number > 10 && data?.trade_mgmt_number <= 30
              ? 'w-[40%]'
              : 'w-[30%]'
          "
          class="h-3 bg-[#E1A325]"
        />
        <div
          :class="data?.trade_mgmt_number > 30 ? 'w-[56%]' : 'w-[45%]'"
          class="bg-[#E14942] progressRight h-3"
        />
      </div>
      <!-- <div :class="`flex  ${!Svg ? 'mt-16' : ''}`">
          <div class="h-3 progressLeft bg-[#3EAF3F] w-[70px]" />
        <div class="h-3 bg-[#E1A325] w-[120px]" />
        <div class="bg-[#E14942] progressRight h-3 w-[160px]" />
      </div> -->
    </div>
  </CardBox>
</template>

<script>
import CardBox from "./SharedComponents/CardBox.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import store, { getTradeHistory } from "@/store";
import router from "@/router";

export default {
  name: "TradeCard",
  components: {
    CardBox,
  },
  setup() {
    return {
      store,
    };
  },

  props: {
    data: {
      type: Object,
    },
    Svg: {
      type: Boolean,
    },
  },

  data() {
    return {
      tradingData: [],
      imagePosition: 0,
      arrowColor: "",
    };
  },

  computed: {
    // formatedN() {
    //   console.log('datat', this.data)
    //   return null
    // },
    formattedDate() {
      return moment(this.data?.exp_date).format("MM/DD/YYYY");
    },
  },

  mounted() {
    this.moveImage();
    // this.formatedN()
  },

  methods: {
    ...mapGetters(["getDeleteTrade"]),
    openModal(item) {
      store.commit("SET_DELETE_TRADE_DATA", item);
      store.commit("SET_DELETE_TRADE", true);
    },
    onCloseModal() {
      store.commit("SET_TRADE_HISTORY", false);
    },
    routeToData(id) {
      getTradeHistory(id);
      router.push("/trade-history");
    },

    angleToPercentage(angle) {
      console.log("angle==>", angle);
      this.moveImage(angle);
      const maxAngleSemiCircle = angle < 30.1 ? 50 : 65;
      const percentage = (angle / maxAngleSemiCircle) * 100;
      console.log("percentage", angle.toFixed(2));
      return Number(percentage);
    },
    moveImage(number) {
      // Increment position value (0 to 100) to move the image
      console.log("hello percentage", number);
      // Reset position after reaching 100 (optional)
      if (number <= 10) {
        this.arrowColor = "#3EAF3F";
      } else if (number > 10 && number <= 30) {
        this.arrowColor = "#E1A325";
      } else {
        this.arrowColor = "#E14942";
      }
      return number;
    },

    exclusiveDays(item) {
      console.log("exclusiveDays", item);
      const diffDate = moment(item).diff(moment().format("YYYY-MM-DD"), "days");
      console.log("exclusiveDays", diffDate);
      return diffDate;
    },
  },
  watch: {
    data: {
      handler() {
        this.moveImage();
      },
    },
  },
};
</script>
<style scoped>
.progressLeft {
  border-bottom-left-radius: 20px;
}

.progressRight {
  border-bottom-right-radius: 20px;
}

.move-image {
  position: relative;
  /* To allow positioning relative to this element */
  /* transition: left 0.3s ease;  */
  /* Transition effect for smooth movement */
}
</style>
