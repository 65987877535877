<template>
  <div class="w-full h-full">
    <BaseModal>
      <div class="lg:w-[38vw] md:w-[50vw] sm:w-[50vw] p-4">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-2">
            <div>
              <span class="text-[20px] font-bold dark:text-white">
                Add a new trade
              </span>
              <p
                class="text-[12px] text-lightTextColor dark:text-darkTextColor"
              >
                You can update your subscription later
              </p>
            </div>
          </div>
          <div
            :class="`${
              isLoading ? 'pointer-events-none' : ''
            } cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 h-fit rounded-full p-2`"
            @click="onCloseModal"
          >
            <CrossIconSvg />
          </div>
        </div>
        <div class="space-y-6 pt-4">
          <p v-if="error" class="text-[13px] font-semibold text-[#FD3232]">
            {{ error }}
          </p>
          <div class="flex flex-col space-y-1">
            <div class="flex flex-col space-y-1">
              <div class="flex flex-col space-y-1" id="tradeElement1">
                <span
                  class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
                  >Trade Reference Name*</span
                >
                <TextInput
                  :error="!tradeRefName ? error : ''"
                  :maxLength="15"
                  placeholder="Eg:My Trade 2"
                  :value="tradeRefName"
                  :handleChange="updateTradeName"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col space-y-1" id="tradeElement2">
            <span
              class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
              >Expiration Date*</span
            >
            <div
              :class="`w-full flex items-center bg-black/5 dark:bg-textInputDark opacity-80 rounded-md p-2 py-1 relative ${
                error ? 'border-2 rounded-md border-red-700' : 'border-0'
              }`"
            >
              <flat-pickr
                v-model="selectedDate"
                placeholder="MM/DD/YYYY"
                :class="`w-full text-[14px] text-black dark:placeholder:text-darkTextColor placeholder:text-lightTextColor dark:text-white bg-transparent rounded-md font-medium h-full border-none focus:border-none focus:outline-none `"
                :config="flatpickrConfig"
                @click="() => (this.error = '')"
              ></flat-pickr>

              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  :stroke="`${store.state.theme ? 'white' : '#3D3D3D'}`"
                  class="w-5 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col items-start w-full h-[20%] justify-between --space-x-4"
            id="tradeElement3"
          >
            <div class="flex space-x-4">
              <RiskFactorInput
                v-model="callStrikePrice"
                :priceValue="callStrikePrice"
                v-on:increment="handleIncrement('callstrike')"
                v-on:decrement="handleDecrement('callstrike')"
                heading="Call Strike Price*"
                :error="!callStrikePrice ? error : ''"
              />
              <RiskFactorInput
                v-model="putStrikePrice"
                :priceValue="putStrikePrice"
                v-on:increment="handleIncrement('putstrike')"
                v-on:decrement="handleDecrement('putstrike')"
                heading="Put Strike Price*"
                :error="!putStrikePrice ? error : ''"
              />
            </div>

            <div
              v-if="cStrikePrice?.toString().split('').length > 7 || pStrikePrice?.toString().split('').length > 7"
              class="text-red-500 text-[12px] pt-2"
            >
              Invalid input strike price!
            </div>
          </div>

          <div class="flex flex-col space-y-1">
            <span
              class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
              >Email</span
            >
            <TextInput
              :disabled="true"
              placeholder="Eg: yourmail@domain.com"
              :value="userEmail"
              :mainClass="'bg-gray-50 !text-[#B0B0B0] font-[300]'"
            />
            <span
              class="text-lightTextColor dark:text-darkTextColor text-[11px]"
              >To change your email address
              <span
                class="text-btnPrimary dark:text-btnPrimaryDark text-[12px] font-semibold hover:underline cursor-pointer"
                >contact us</span
              ></span
            >
          </div>
          <div class="flex flex-col space-y-1">
            <!-- <span class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >City</span -->
            <!-- > -->
            <div class="flex flex-col space-y-1">
              <span
                class="text-[13px] text-lightTextColor dark:text-darkTextColor font-semibold"
                >Phone Number</span
              >
              <TextInput
                placeholder="_ _ _ _ _ _ _ _ _ _ _"
                v-model="phoneNumber"
                :firstimageSrc="require('../../../assets/images/USFlag.png')"
                imageClass="w-8 h-6"
                :disabled="true"
                type="tel"
                :name="'phoneNumber'"
                :mainClass="'bg-gray-50 !text-[#B0B0B0] font-[300]'"
                :handleChange="onChangeHandler"
                :onInputClick="
                  () => {
                    this.phoneMessage = '';
                  }
                "
              />
              <!-- <div
                  class="flex items-center space-x-2 pt-2"
                  v-if="phoneMessage.length"
                >
                  <WarningIconSvg fill="#FF3030" v-if="!isSuccessPhone" />
                  <span
                    :class="`${
                      isSuccessPhone ? 'text-[#3EAF3F]' : 'text-[#FF3030]'
                    } text-[13px]`"
                    >{{ this.phoneMessage }}</span
                  >
                </div> -->
              <span
                class="text-lightTextColor dark:text-darkTextColor text-[11px]"
                >To change your phone number
                <span
                  class="text-btnPrimary dark:text-btnPrimaryDark text-[12px] font-semibold hover:underline cursor-pointer"
                  >contact us</span
                ></span
              >
            </div>
          </div>
          <div class="flex items-center space-x-4">
            <CustomButton
              v-if="!isLoading"
              buttonType="secondary"
              title="Discard Changes"
              @onClick="onDiscardChanges"
              customStyle="dark:bg-lightTextColor/70 dark:hover:bg-lightTextColor/60"
            />
            <div class="w-full" id="tradeElement4">
              <CustomButton
                buttonType="primary"
                :title="getTitle()"
                :isLoading="isLoading"
                @onClick="
                  `${
                    getTitle() === 'Upgrade your Plan'
                      ? onUpgradePlan()
                      : addNewTrade()
                  }`
                "
                :loaderText="'Loading'"
              />
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <BaseModal v-if="showUpgradeModal">
      <div class="md:w-[35vw] w-full h-full p-0">
        <div class="flex justify-end w-full">
          <div
            :class="`${
              isLoading ? 'pointer-events-none' : ''
            } cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 h-fit rounded-full p-2`"
            @click="() => (showUpgradeModal = false)"
          >
            <CrossIconSvg />
          </div>
        </div>
        <div class="flex flex-col space-y-4 p-3">
          <span
            class="text-[14px] text-lightTextColor dark:text-darkTextColor font-semibold"
            >You have reached your Add Trade limit ({{ getLimits() }}). To add
            more trades you need to upgrade your current plan.</span
          >
          <div class="flex items-center space-x-4">
            <CustomButton
              title="Cancel"
              buttonType="secondary"
              @onClick="() => (showUpgradeModal = false)"
            />
            <CustomButton
              title="Go to Subscriptions"
              buttonType="primary"
              @onClick="goToSubscriptions()"
            />
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import service from "@/services/tradeApi";
import BaseModal from "../../SharedComponents/BaseModal.vue";
import TextInput from "@/components/SharedComponents/TextInput.vue";
import CrossIconSvg from "../../../assets/svgs/CrossIconSvg.vue";
import CustomButton from "@/components/SharedComponents/CustomButton.vue";
import RiskFactorInput from "@/components/input/RiskFactorInput.vue";
import flatPickr from "vue-flatpickr-component";
import AddTrade from "@/services/tradeApi";
import store, { getUserDetailsFromStore } from "@/store";
import moment from "moment";
import router from "@/router";
// import './index.css'
import tourService from "@/services/tourService";
import tradeApi from "@/services/tradeApi";

export default {
  name: "ActiveTradeModal",
  components: {
    BaseModal,
    TextInput,
    CrossIconSvg,
    RiskFactorInput,
    flatPickr,
    CustomButton,
  },
  data() {
    return {
      address: "",
      showUpgradeModal: false,
      phoneNumber: "",
      userEmail: "",
      callStrikePrice: 0,
      cStrikePrice: 0,
      // callStrikePrice: 4500,
      // putStrikePrice: 4600,
      putStrikePrice: 0,
      pStrikePrice: toString(this.putStrikePrice),
      isAddressSaving: false,
      isLoading: false,
      selectedDate: "",
      tradeRefName: "",
      tourObj: null,
      error: "",
      flatpickrConfig: {
        enable: [
          function (date) {
            return date.getDay() === 5;
          },
        ],
        dateFormat: "m/d/Y",
        minDate:
          new Date().getDay() === 5
            ? new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + 1
              )
            : "today",
      },
    };
  },
  setup() {
    return {
      store,
    };
  },
  mounted() {
    this.getObject();
    console.log("value", store.getters.getUserDetails);
    this.userEmail = store.getters.getUserDetails?.email;
    this.phoneNumber = store.getters.getUserDetails?.phone;

    // switch off web tour

    // if (!this.tourObj.activeTradeModal && this.isProfileComplete) {
    //   this.startTradeTour();
    // }
  },
  created() {
    this.getCurrentSPx();
  },
  methods: {
    async getCurrentSPx() {
      const res = await service.getSpxPrice();
      console.log("res from the api==>", res);
      if (res && res?.price) {
        this.currentSpx = res.price;
        this.callStrikePrice = Math.round(this.currentSpx) + 5;
        this.putStrikePrice = Math.round(this.currentSpx) - 5;
      } else {
        this.cuurentSpx = 0;
        this.callStrikePrice = 0;
        this.putStrikePrice = 0;
      }
    },
    startTradeTour() {
      const steps = [
        {
          element: "#tradeElement1",
          intro:
            "Give a name to your trade just for your reference for tracking purposes.",
        },
        {
          element: "#tradeElement2",
          intro: "Select the expiration date of your trade here.",
        },

        {
          element: "#tradeElement3",
          intro: "You can change your Call strike and Put strike prices here.",
        },
        {
          element: "#tradeElement4",
          intro: "By clicking on it, you can add your trade",
        },

        // Add more steps as needed
      ];
      tourService.startTour(steps, this.onTourEnd);
    },
    getObject() {
      const storedObject = localStorage.getItem("appTour");

      if (storedObject) {
        this.tourObj = JSON.parse(storedObject);
      } else {
        return;
      }
    },
    updateTradeName(e) {
      this.tradeRefName = e.target.value;
    },
    onTourEnd() {
      this.tourObj.activeTradeModal = true;

      localStorage.setItem("appTour", JSON.stringify(this.tourObj));
    },
    onCloseModal() {
      this.$emit("onClose");
    },
    getLimits() {
      if (this.isStandardPlan && this.tradeData?.length >= 2) {
        return 2;
      } else if (this.isGoldPlan && this.tradeData?.length >= 4) {
        return 4;
      } else {
        return;
      }
    },
    onUpgradePlan() {
      this.showUpgradeModal = true;
    },
    onDiscardChanges() {
      this.tradeRefName = "";
      this.selectedDate = "";
      this.$emit("onClose");
    },
    goToSubscriptions() {
      this.showUpgradeModal = false;
      this.onCloseModal();
      router.push("/settings");
      store.commit("SET_NAVBAR_LABEL", "Subscription Plans");
      store.commit("SET_SETTINGS_TAB", "Subscription Plans");
    },
    getTitle() {
      if (this.isStandardPlan && this.tradeData?.length >= 2) {
        return "Upgrade your Plan";
      } else if (this.isGoldPlan && this.tradeData?.length >= 4) {
        return "Upgrade your Plan";
      } else {
        return "Place a Trade";
      }
    },

    handleIncrement(val) {
      if (val == "spx") {
        this.currentSpx = this.currentSpx + 0.1;
      } else if (val == "callstrike") {
        this.callStrikePrice = this.callStrikePrice + 0.1;
      } else if (val == "putstrike") {
        this.putStrikePrice = this.putStrikePrice + 0.1;
      }
    },
    onChangeHandler(event) {
      this[event.target.name] = event.target.value;
      if (event.target.name === "phoneNumber") {
        if (this.phoneNumber.length === 3 && !this.phoneNumber.includes("(")) {
          this.phoneNumber = `(${this.phoneNumber}) `;
        }
      }
    },
    loadingTradingData: function () {
      tradeApi
        .tradeDetails()
        .then((d) => {
          store.commit("SET_TRADE_DATA_LIST", d.data.trades);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    handleDecrement(val) {
      if (val == "spx") {
        if (this.currentSpx > 0) {
          this.currentSpx = this.currentSpx - 0.1;
        }
      } else if (val == "callstrike") {
        if (this.callStrikePrice > 0) {
          this.callStrikePrice = this.callStrikePrice - 0.1;
        }
      } else if (val == "putstrike") {
        if (this.putStrikePrice > 0) {
          this.putStrikePrice = this.putStrikePrice - 0.1;
        }
      } else {
        return;
      }
    },

    async addNewTrade() {
      if (
        this.tradeRefName &&
        this.callStrikePrice &&
        this.putStrikePrice &&
        this.selectedDate &&
        this.userEmail &&
        this.phoneNumber
      ) {
        console.log(
          "date new",
          this.putStrikePrice.length,
          this.callStrikePrice.length
        );
        const formattedDate = moment(this.selectedDate).format("YYYY-MM-DD");
        console.log("formattedDate", formattedDate);

        this.isLoading = true;
        const payload = {
          expiry: formattedDate,
          call_strike_price: this.callStrikePrice,
          put_strike_price: this.putStrikePrice,
          email: this.userEmail,
          reference_name: this.tradeRefName,
          phone: this.phoneNumber,
        };
        const res = await AddTrade.addTrade(payload);
        console.log("RES", res);
        if (res.data.status) {
          store.commit("SET_PROFILE_LOADING", true);
          console.log("loading trade");
          this.loadingTradingData();
          this.onCloseModal();
        }
      } else {
        this.error = "All fields with * are required";
      }

      this.isLoading = false;
    },
  },
  computed: {
    userDetails() {
      return getUserDetailsFromStore();
    },
    isStandardPlan() {
      return store.getters.isStandardProduct;
    },
    isGoldPlan() {
      return store.getters.isGoldProduct;
    },
    tradeData() {
      return store.getters.getTradeData;
    },
    isProfileComplete() {
      let user = this.userDetails;
      console.log("userDetails11", this.userDetails.profile_status);
      return user?.is_profile_complete ?? false;
    },
  },
  watch: {
    callStrikePrice(val) {
      this.cStrikePrice = val;

      console.log(
        "val===>",
        val,
        this.cStrikePrice.toString().split("").length
      );
    },
    putStrikePrice(val) {
      this.pStrikePrice = val;
      console.log(
        "val===>",
        val,
        this.pStrikePrice.toString().split("").length
      );
    },
  },
};
</script>
<style scoped>
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
</style>
