<template>
  <div class="sm:space-y-8 space-y-0 w-full flex flex-col sm:pb-16 pb-44 ">

    <SignupProgress v-if="!isProfileLoading && !isProfileComplete" />

    <div
      class="flex lg:space-x-8 sm:space-x-0 lg:flex-row sm:flex-col lg:space-y-0 md:space-y-5 space-y-5 sm:pt-0 pt-4 flex-col w-full"
    >
      <div class="w-full" id="dashboardElement1">
        <CardBox
          customClass=" rounded-3xl w-full lg:px-8 py-6 flex justify-between items-center"
        >
          <div class="text-[16px]">
            <p class="font-semibold dark:text-white/80">S&P 500</p>
            <p class="text-[#888888] sm:text-[13px] xl:text-[18px] font-semibold dark:text-white/40">
              INDEXSP: .INX
            </p>
            <p
              class="text-[11px] text-lightTextColor dark:text-darkTextColor/70"
            >
              Last Updated: {{ currentDateFormatted }}
            </p>
          </div>
          <div class="flex dark:text-white items-start">
            <p class="text-[12px] font-bold dark:text-[#DCDCDC]">$</p>
            <p  
              class="font-bold text-[22px] dark:text-[#DCDCDC]"
              v-if="currentPrice"
            >
              {{ currentPrice }}
            </p>
            <p class="font-bold text-[22px] dark:text-[#DCDCDC]" v-else>...</p>
          </div>
        </CardBox>
      </div>
      <div class="w-full" id="dashboardElement2">
        <CardBox
          customClass=" sm:px-4 sm:py-4 px-3 py-3 rounded-3xl w-full flex justify-between items-center "
        >
          <div class="flex items-center w-full space-x-3">
            <img
              src="../../assets/images/dashboard/image 12.png"
              width="70"
              class="rounded-2xl"
            />

            <div class="pt-1 w-full space-y-1" v-if="instructorComment">
              <p
                class="sm:text-[18px] text-[16px] font-bold dark:text-white/80"
              >
                {{
                  isProfileComplete
                    ? instructorComment?.label
                    : "Instructors Comment"
                }}
              </p>
              <div
                class="flex sm:flex-row flex-col items-center relative sm:space-x-2 whitespace-nowrap w-full"
              >
                <p
                  :class="`italic w-full sm:text-[13px] xl:text-[18px] text-[#3D3D3D] dark:text-white/60 ${
                    store?.getters?.getUserDetails?.is_profile_complete
                      ? ' '
                      : 'blur-[3px] select-none'
                  } `"
                >
                  "{{ displayMessage(instructorComment?.value) }}"
                </p>
                <div
                  v-if="showSeeMore && isProfileComplete"
                  @click="toggleSeeMoreHandler"
                  class="w-full"
                >
                  <p
                    class="text-lightTextColor dark:text-darkTextColor text-[11px] hover:cursor-pointer hover:font-bold hover:underline"
                  >
                    Read More
                  </p>
                </div>
                <p
                  v-if="!store?.getters?.getUserDetails?.is_profile_complete"
                  class="text-btnPrimary dark:text-yellow-400 sm:bottom-1 sm:right-0 -right-2 absolute sm:text-[14px] text-[12px] font-bold underline cursor-pointer"
                >
                  Upgrade to view
                </p>
              </div>
              <div>
                <p
                  v-if="isProfileComplete"
                  class="text-[11px] text-lightTextColor dark:text-darkTextColor/70"
                >
                  Last Updated:
                  {{ formattedDate(instructorComment?.last_updated) }}
                </p>
              </div>
            </div>
            <div class="w-full" v-else>
              <SkeletonLoader :loadingLines="3" />
            </div>
          </div>
          <div class="pt-2" v-if="isProfileComplete">
            <img src="../../assets/images/dashboard/image 15.png" width="40" />
          </div>
        </CardBox>
      </div>
    </div>

    <div
      class="flex lg:space-x-8 w-full lg:flex-row flex-col lg:space-y-0 space-y-10 lg:pt-0 pt-8">
      <SpxCondorCard
        :id="
          spx?.spreadType === '10.00'
            ? 'dashboardElement3'
            : 'dashboardElement4'
        "
        :rows="spx.rows"
        :ic="spx.ic"
        :lastUpdate="spx?.lastUpdate"
        :spreadType="spx?.spreadType"
        :isCompleteProfile="store.getters.getUserDetails?.is_profile_complete"
        v-for="(spx, i) in spxCondor"
        :key="i"
      />
    </div>

    <BaseModal
      v-if="store.getters.getInitialSuccess"
      @close="cancelFirstMessage"
    >
      <div class="md:w-[35vw] w-full h-full">
        <div class="flex flex-col space-y-6 pt-3">
          <div class="w-full flex justify-end px-4">
            <CrossButtonSvg @close="cancelFirstMessage"></CrossButtonSvg>
          </div>
          <div class="flex items-center justify-center w-full h-full">
            <div class="flex flex-col space-y-4">
              <div class="flex flex-col space-y-2">
                <h1 class="text-[22px] font-bold text-black text-center">
                  Congratulations!
                </h1>
                <p class="text-[14px] text-black text-center">
                  Your account has been successfully created.
                </p>
              </div>
              <div>
                <img src="@/assets/images/shakinghands.png" alt="" />
              </div>
              <div class="pb-3">
                <CustomButton
                  @onClick="closeSignupSuccess"
                  button-type="success"
                  title="Complete your profile"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal v-if="toggleSeeMore">
      <div class="md:w-[35vw] w-full h-full p-4">
        <div class="w-full flex justify-between">
          <div class="pt-1">
            <h1 class="text-[18px] font-bold text-black dark:text-white">
              {{ instructorComment?.label }}
            </h1>
          </div>
          <div
            class="cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800 rounded-full p-1"
            @click="() => (this.toggleSeeMore = false)"
          >
            <CrossIconSvg />
          </div>
        </div>
        <div class="pt-3 flex flex-col space-y-4">
          <div>
            <p
              class="text-[14px] text-lightTextColor dark:text-darkTextColor italic"
            >
              {{ instructorComment?.value }}
            </p>
          </div>
          <div>
            <p
              class="text-[11px] text-lightTextColor dark:text-darkTextColor/70"
            >
              Last Updated: {{ formattedDate(instructorComment?.last_updated) }}
            </p>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
// import Navbar from "../../components/NavBar"
// import Badge from "../../components/SharedComponents/Badge.vue";

import CardBox from "../../components/SharedComponents/CardBox.vue";
// import TOS from '../../assets/images/dashboard/CC-Logos/TOS.png';
import TOS from "../../assets/images/dashboard/CC-Logos/toSwim.png";
import SCHWAB from "../../assets/images/dashboard/CC-Logos/charlesName.png";
import Fidelity from "../../assets/images/dashboard/CC-Logos/Fidelity.png";
// import TradeKing from "../../assets/images/dashboard/CC-Logos/TradeKing.png";
// import optionshouse from "../../assets/images/dashboard/CC-Logos/optionshouse1.png";
import CustomButton from "@/components/SharedComponents/CustomButton.vue";
import CrossButtonSvg from "@/components/Modal/CrossButtonSvg.vue";
import BaseModal from "@/components/SharedComponents/BaseModal.vue";
import store, {clickOnProfileSettingButton,getUserDetailsFromStore,} from "@/store";
import SpxCondorCard from "@/pages/home/SpxCondorCard.vue";
import tradeApi from "@/services/tradeApi";
import moment from "moment";
import SignupProgress from "../../components/signupProgress";
// import TradeStation from "../../assets/images/dashboard/CC-Logos/trade.png";
// import InteractiveBroker from "../../assets/images/dashboard/CC-Logos copy/Interactive_Brokers.png";
import ETrade from "../../assets/images/dashboard/CC-Logos/e.png";
// import VueHorizontal from "vue-horizontal";
// import Inter from "../../assets/images/dashboard/CC-LogosDark/int.png";

import EDark from "@/assets/images/dashboard/CC-LogosDark/E-Dark.png";
import ThinkSwim from "@/assets/images/dashboard/CC-LogosDark/thinkSwim.svg";
import fidelity from "@/assets/images/dashboard/CC-LogosDark/fidelity.svg";
import CrossIconSvg from "@/assets/svgs/CrossIconSvg.vue";
// import Options from "@/assets/images/dashboard/CC-LogosDark/options.svg";
// import tradeKing from "@/assets/images/dashboard/CC-LogosDark/tradeKing.svg";

import "intro.js/introjs.css";
import tourService from "@/services/tourService";
import SkeletonLoader from "@/components/SharedComponents/SkeletonLoader.vue";

export default {
  name: "HomePage",

  components: {
    SpxCondorCard,
    CrossButtonSvg,
    BaseModal,
    CustomButton,
    CardBox,
    CrossIconSvg,
    SignupProgress,
    // VueHorizontal,
    SkeletonLoader,
    // Badge
    // Navbar,
    // SideBar
  },
  setup() {
    return {
      store,
    };
  },
  data() {
    return {
      isAuthenticated: localStorage.getItem("token"),

      // newDate,
      header: [
        { logo: TOS, width: "180", name: "TOS" },
        { logo: SCHWAB, width: "90", name: "schwab" },
        { logo: Fidelity, width: "120", name: "fidelity" },
        // { logo: TradeKing, width: "100", name: "TradeKing" },
        // { logo: optionshouse, width: "150", name: "optionsHouse" },
        // { logo: TradeStation, width: "150", name: "TradeStation" },
        // { logo: InteractiveBroker, width: "165", name: "InteractiveBroker" },
        { logo: ETrade, width: "140", name: "ETrade" },
      ],

      DarkHeader: [
        { logo: ThinkSwim, width: "160", name: "TOS" },
        { logo: SCHWAB, width: "90", name: "schwab" },
        { logo: fidelity, width: "120", name: "fidelity" },
        // { logo: tradeKing, width: "100", name: "TradeKing" },
        // { logo: Options, width: "150", name: "optionsHouse" },
        // { logo: TradeStation, width: "150", name: "TradeStation" },
        // { logo: Inter, width: "180", name: "InteractiveBroker" },
        { logo: EDark, width: "140", name: "ETrade" },
      ],
      completeProfile: false,
      currentDateFormatted: "11/30/2023 5:40am",
      currentPrice: "",
      toggleSeeMore: false,
      instructorMessage:
        "Bullish to Neutral Market.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      spxCondor: [],
      condorIronData: {},
      condorMap: [],
      showSeeMore: false,
      profilSteps: [
        {
          step: "1",
          name: "Sign up for an account",
        },
        {
          step: "4",
          name: "Complete your profile",
        },
        {
          step: "2",
          name: "Address and Billing information",
        },

        {
          step: "3",
          name: "Select a subscription plan",
        },
      ],
      tourObj: null,
    };
  },
  methods: {
    startDashboardTour() {
      setTimeout(() => {
        const steps = [
          {
            element: "#dashboardElement1",
            intro: "It shows last closing price of S&P 500 index",
          },
          {
            element: "#dashboardElement2",
            intro:
              "You can read our technical trader’s recommendations/comments here.",
          },
          {
            element: "#dashboardElement3",
            intro: "It is showing options for $10.00 spreads",
          },
          {
            element: "#dashboardElement4",
            intro: "It is showing options for $5.00 spreads",
          },
          {
            element: document.querySelector("#dashboardElement5"),
            intro:
              "Here you can monitor your trades’ risk management number, with the help of the CondorCash proprietary risk management tool.",
          },
          {
            element: document.querySelector("#dashboardElement8"),
            intro: "It shows the trade history of your current Active trades",
          },
          {
            element: document.querySelector("#dashboardElement6"),
            intro:
              "Trade risk calculator is our proprietary tool, it tells you the status of your trade.",
          },
          {
            element: document.querySelector("#dashboardElement7"),
            intro: "Calculate credits and returns using this section.",
          },
          // Add more steps as needed
        ];
        tourService.startTour(steps, this.onTourEnd);
      }, 500);
    },

  // switch off webtour

    // getObject() {
    //   // Retrieve the JSON string from localStorage
    //   const storedObject = localStorage.getItem("appTour");

    //   // If the storedObject is not null or undefined, parse it into an object
    //   if (storedObject) {
    //     this.tourObj = JSON.parse(storedObject);
    //     console.log(" this.tourObj", this.tourObj);
    //   } else {
    //     return;
    //   }
    // },

    cancelFirstMessage() {
      store.commit("SET_INITIAL_SUCCESS", false);
    },
    formattedDate(date) {
      return moment(date).format("MM/DD/YYYY h:mma");
    },
    toggleSeeMoreHandler() {
      this.toggleSeeMore = true;
    },
    closeSignupSuccess() {
      store.commit("SET_INITIAL_SUCCESS", false);
      clickOnProfileSettingButton();
    },
    onTourEnd() {
      store.commit("SET_TOUR_NEXT_STEP", "ActiveTrades");

      this.tourObj.dashboard = true;

      localStorage.setItem("appTour", JSON.stringify(this.tourObj));
      // this.$router.push("/active-trades");
    },
    displayMessage(msg) {
      if (msg.length >= 27) {
        this.showSeeMore = true;
        return msg.slice(0, 27) + "...";
      } else {
        return msg;
      }
    },
    row_data(date, price, days, side, type) {
      return { date: date, price: price, days: days, side: side, type: type };
    },
    loadIronCondorData(condorIronData) {
      this.condorIronData = condorIronData;
      console.log("tradeApi condorIronData on Component", condorIronData);
      let expiry_date_formatted = "";
      let daysDifference = 0;
      if (condorIronData?.five) {
        let expiry_date = condorIronData?.five?.expiry_date;
        let expiry_date_obj = moment(expiry_date);
        expiry_date_formatted = expiry_date_obj.format("Do MMM, YYYY");
        const currentDate = moment();
        daysDifference = expiry_date_obj.diff(currentDate, "days");
        console.log(expiry_date_formatted);
      }
      daysDifference = `${daysDifference} Days`;
      let priceKeys = Object.keys(condorIronData);
      let condorData = [];
      for (let p of priceKeys) {
        let data = {};
        let condorIronDatum = condorIronData[p];
        data["rows"] = [];
        data["spreadType"] = p == "five" ? "5.00" : "10.00";
        data["lastUpdate"] = moment(condorIronDatum?.date_time).format(
          "MM/DD/YYYY h:mma"
        );
        data["ic"] = condorIronDatum?.ic;
        console.log(p, condorIronDatum);
        if (condorIronDatum?.buy_call_strike_price) {
          data.rows.push(
            this.row_data(
              expiry_date_formatted,
              condorIronDatum?.buy_call_strike_price,
              daysDifference,
              "BUY",
              "CALLS"
            )
          );
        }
        if (condorIronDatum?.buy_put_strike_price) {
          data.rows.push(
            this.row_data(
              expiry_date_formatted,
              condorIronDatum?.buy_put_strike_price,
              daysDifference,
              "BUY",
              "PUTS"
            )
          );
        }
        if (condorIronDatum?.sell_call_strike_price) {
          data.rows.push(
            this.row_data(
              expiry_date_formatted,
              condorIronDatum?.sell_call_strike_price,
              daysDifference,
              "SELL",
              "CALLS"
            )
          );
        }
        if (condorIronDatum?.sell_put_strike_price) {
          data.rows.push(
            this.row_data(
              expiry_date_formatted,
              condorIronDatum?.sell_put_strike_price,
              daysDifference,
              "SELL",
              "PUTS"
            )
          );
        }

        condorData.push(data);
        //{date: "24th Dec, 2023", price: "4,135.00", days: "21 Days", side: "Buy", type: "CALLS",}
      }
      if (condorData.length > 0) {
        this.spxCondor = condorData;
        console.log("spxCondor==>", this.spxCondor)
        store.commit("SET_IRONCONDOR_DATA", condorData);
      }

      console.log("Resp123", condorData);

      console.log("User Successful");
    },
    async CondorIron() {
      console.log("new dart", new Date("2023-12-23T20:25.1660"));
      console.log(
        "condor==>",
        store.getters.getUserDetails?.is_profile_complete,
        store.getters.getUserDetails,
        this.isProfileLoading
      );
      if (
        store.getters.getUserDetails?.is_profile_complete &&
        !this.isProfileLoading
      ) {
        try {
          let condorIronData = await tradeApi.getIronCondorData();
          this.loadIronCondorData(condorIronData);
        } catch (err) {
          console.log(err);
        }
      } else {
        return;
      }
    },
    
  },
  computed: {
    isProfileLoading() {
      return store.state.isProfileLoading;
    },
    getDefaultSpx() {
      return store.state.spxData;
    },
    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileComplete() {
      let user = this.userDetails;
      console.log("userDetails", this.userDetails.profile_status);
      return user?.is_profile_complete ?? false;
    },
    instructorComment() {
      return store.getters.getSiteSettings?.app_instructor_comment;
    },
  },
  mounted() {
      // switch off webtour
    // this.getObject();

    let condorIronData = store.getters?.getSpxData;
    if (Object.values(condorIronData).length > 0) {
      this.loadIronCondorData(condorIronData);
    } else {
      this.CondorIron();
    }

    tradeApi
      .getSpxPrice()
      .then((d) => {
        console.log("getSpxPrice", d);
        if (d?.price && d?.time) {
          const formattedDate = moment(d.time).format("MM/DD/YYYY h:mma");

          console.log("formattedDate", formattedDate);
          this.currentDateFormatted = formattedDate;
          this.currentPrice = d.price.toLocaleString("en-US");
        }
      })
      .catch((e) => {
        console.log("Error ", e);
      });

      // switch off webtour
      // if (this.tourObj) {
      //   if (this.isAuthenticated &&!this.tourObj.dashboard &&this.isProfileComplete) {

      //     this.startDashboardTour();
          
      //     console.log("ourObj", this.tourObj);
      //   }
      // }
  },

  watch: {
    isProfileLoading: function (n, o) {
      console.log("isProfileLoading", n, o);
      if (!n) {
        this.CondorIron();
      }
    },
  },
};
</script>

<style></style>
<style scoped></style>
