<template>
  <div></div>
</template>

<script>

import userApi from "@/services/userApi";
import store from "@/store";
import {afterSigninActivty} from "@/pages/userStory/login/afterSigninActivty";

export default {
  beforeRouteEnter(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem("isUserAuthenticated", true);

      userApi.userDetails()
        .then((resp) => {
          console.log("resp==>", resp);
          store.commit("SET_PROFILE_LOADING", true);
          setTimeout(() => {
            const afterCall = () => {
              console.log("access token complete");
              store.commit("SET_PROFILE_LOADING", false);
            };
            afterSigninActivty(resp, true, afterCall, token);
            if (resp?.data?.status) {
              const payload = {
                user_id: resp?.data?.user_detail?.id,
                switching: 'switched_to_new',
                token: token,
                date_time: ''
              };
              userApi.userCheckin(payload).then((res) => {
                console.log("checkin==>", res);
              }).catch((e) => {
                console.log("e", e);
              });
            }
          }, 10);
        })
        .catch((e) => {
          console.log(e);
          store.commit("SET_PROFILE_LOADING", false);
        });
    }

    next();
  }
};
</script>

<style scoped>

</style>