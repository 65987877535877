import axios from "@/api/axios";

import {
  SIGN_IN,
  SIGN_UP,
  USER_CHECKIN,
  USER_DETAIL,
  UPDATE_ADDRESS_INFO,
  UPDATE_BASIC_INFO,
  UPDATE_BILLING_INFO,
  UPDATE_SUBS,
  RESET_PROFILE,
  VERIFY_EMAIL,
  SEND_MOBILE_OTP,
  VERIFY_MOBILE_OTP,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  NOTIFICATIONS_UPDATE,
  FACEBOOK_LOGIN,
  GOOGLE_LOGIN,
  LINKDIN_LOGIN,
  CHANGE_PASSWORD,
  INVOICE_LIST,
  SUBSCRIPTION_PLANS,
  UPDATE_PLAN,
  GET_SITE_SETTINGS,
  CONTACT_US,
  CANCEL_SUBSCRIPTION,
  USER_SESSION_CHECK_IN,
  USER_SESSION_CHECK_OUT
} from "../api/apiRoutes";

export default {
  userSignUp: async (payload) => {
    const response = await axios.post(SIGN_UP, payload);
    return response;
  },
  userSignIn: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(SIGN_IN, payload);
    return response;
  },

  checkIn: async (payload) => {
    try {
      console.log("check-in payload", payload);
      const response = await axios.post(USER_SESSION_CHECK_IN, payload);
      console.log("check-in response", response);
      if(response){
        return response;
      }else{
        return false
      }
    } catch (error) {
      console.error("Check-in failed:", error);
      return false      
    }
  
  },

  checkOut: async (userId) => {
    try {
      console.log("check-out payload", userId);
      const response = await axios.post(`${USER_SESSION_CHECK_OUT}?user_id=${userId}`);
      console.log("check-out response", response);
      if(response){
        return response;
      }else{
        return false
      }
    } catch (error) {
      console.error("Check-out failed:", error);
      return false  
    }
  },

  updateAddressInfo: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(UPDATE_ADDRESS_INFO, payload);
    return response;
  },
 
  userCheckin: async (payload) => {
    const response = await axios.post(USER_CHECKIN, payload);
    return response;
  },
  updateSubs: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(UPDATE_SUBS, payload);
    return response;
  },
  updateBasicInfo: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(UPDATE_BASIC_INFO, payload);
    return response;
  },
  updateBillingInfo: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(UPDATE_BILLING_INFO, payload);
    return response;
  },
  resetProfile: async (payload) => {
    console.log("payload", payload);
    const response = await axios.post(RESET_PROFILE, payload);
    return response;
  },

  userDetails: async () => {
    // console.log("payload", payload);
    const response = await axios.get(USER_DETAIL);
    return response;
  },
  verifyUserEmail: async (token) => {
    // console.log("payload", payload);
    const response = await axios.get(`${VERIFY_EMAIL}?token=${token}`);
    return response;
  },
  invoiceList: async () => {
    const response = await axios.get(INVOICE_LIST);
    return response;
  },
  sendMobileOTP: async (payload) => {
    // console.log("payload", payload);
    const response = await axios.post(`${SEND_MOBILE_OTP}`, payload);
    return response;
  },
  verifyMobileOTP: async (payload) => {
    // console.log("payload", payload);
    const response = await axios.post(`${VERIFY_MOBILE_OTP}`, payload);
    return response;
  },
  forgetPassword: async (payload) => {
    const response = await axios.post(`${FORGET_PASSWORD}`, payload);
    return response;
  },
  resetPassword: async (payload) => {
    const response = await axios.post(`${RESET_PASSWORD}`, payload);
    return response;
  },
  notificationUpdate: async (payload) => {
    const response = await axios.post(`${NOTIFICATIONS_UPDATE}`, payload);
    return response;
  },
  facebooklogin: async () => {
    const response = await axios.get(FACEBOOK_LOGIN);
    return response;
  },
  googlelogin: async () => {
    const response = await axios.get(GOOGLE_LOGIN);
    return response;
  },
  linkdinlogin: async () => {
    const response = await axios.get(LINKDIN_LOGIN);
    return response;
  },
  cancelSubscription: async () => {
    const response = await axios.get(CANCEL_SUBSCRIPTION)
    return response
  },
  changePassword: async (payload) => {
    const response = await axios.post(`${CHANGE_PASSWORD}`, payload);
    return response;
  },
  getSubscriptionPlans: async () => {
    const response = await axios.get(SUBSCRIPTION_PLANS);
    return response;
  },
  updatePlan: async (payload) => {
    const response = await axios.post(UPDATE_PLAN, payload);
    return response;
  },
  getSiteSettings: async () => {
    const response = await axios.get(GET_SITE_SETTINGS);
    return response;
  },
  contactUs: async (payload) => {
    const response = await axios.post(CONTACT_US, payload);
    return response;
  },
};
