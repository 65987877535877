var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CardBox',{attrs:{"customClass":" rounded-2xl  w-full !px-0 !py-0 items-center"}},[_c('div',{staticClass:"space-y-2 pl-3 sm:pl-4 -bg-blue-300 sm:px-6 w-full sm:pt-3 pt-1"},[_c('div',{staticClass:"flex justify-between items-center",attrs:{"id":"activeTradeElement2"}},[_c('p',{staticClass:"font-bold text-[16px] dark:text-white"},[_vm._v(" "+_vm._s(_vm.data?.reference_name ?? "Trade 1")+" ")]),_c('div',{staticClass:"cursor-pointer hover:bg-gray-200 hover:dark:bg-gray-600 h-fit rounded-full p-2"},[(_vm.Svg)?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.routeToData(_vm.data?.id)}}},[_c('img',{attrs:{"src":require("../assets/svgs/clock.svg")}})]):_c('img',{attrs:{"src":require("../assets/svgs/cross.svg")},on:{"click":_vm.onCloseModal}})])]),_c('div',{},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('img',{class:`${!_vm.Svg ? 'w-4 h-4' : ''}`,attrs:{"src":require("../assets/svgs/calls.svg")}}),_c('div',{staticClass:"flex dark:text-white"},[_c('p',{class:`${!_vm.Svg ? 'text-[9px]' : 'text-[12px]'}`},[_vm._v("$")]),_c('p',{class:`font-bold ${!_vm.Svg ? 'text-[14px]' : 'text-[18px]'}`},[_vm._v(" "+_vm._s(_vm.data.call_strike?.toLocaleString("en-US"))+" ")])])]),_c('p',{class:`text-[#9E9E9E] dark:text-white/70 ${
          !_vm.Svg ? 'text-[10px]' : 'text-[14px]'
        } pl-8`},[_vm._v(" CALL ")])]),_c('div',{},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('img',{class:`${!_vm.Svg ? 'w-4 h-4' : ''}`,attrs:{"src":require("../assets/svgs/puts.svg")}}),_c('div',{staticClass:"flex dark:text-white"},[_c('p',{staticClass:"text-[12px]"},[_vm._v("$")]),_c('p',{class:`font-bold ${!_vm.Svg ? 'text-[14px]' : 'text-[18px]'}`},[_vm._v(" "+_vm._s(_vm.data?.put_strike?.toLocaleString("en-US"))+" ")])])]),_c('p',{class:`text-[#9E9E9E] dark:text-white/70 ${
          !_vm.Svg ? 'text-[10px]' : 'text-[14px]'
        } pl-8`},[_vm._v(" PUT ")])]),_c('div',{},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('img',{attrs:{"src":require("../assets/svgs/alaram.svg")}}),_c('div',{staticClass:"flex"},[_c('p',{class:`font-bold text-[#FD3232] ${
              !_vm.Svg ? 'text-[12px]' : 'text-[15px]'
            } `},[_vm._v(" "+_vm._s(_vm.exclusiveDays(_vm.data?.exp_date))+" Days Remaining ")])])]),_c('p',{class:`text-[#9E9E9E] dark:text-white/70  ${
          !_vm.Svg ? 'text-[10px]' : 'text-[12px]'
        } pl-7`},[_vm._v(" "+_vm._s(_vm.formattedDate)+" ")])]),_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('img',{attrs:{"src":require("../assets/svgs/line.svg")}}),_c('div',{staticClass:"flex"},[_c('p',{class:`font-bold ${!_vm.Svg ? 'text-[12px]' : 'text-[15px]'}  `,style:({ color: `${_vm.arrowColor}` })},[_vm._v(" "+_vm._s(_vm.moveImage(_vm.data?.trade_mgmt_number))+"% ")])])]),_c('p',{class:`text-[#9E9E9E] dark:text-white/70 ${
            !_vm.Svg ? 'text-[10px]' : 'text-[12px]'
          } pl-7`},[_vm._v(" Trade Risk Factor ")])]),_c('div',{staticClass:"cursor-pointer hover:bg-gray-200 hover:dark:bg-gray-600 h-fit rounded-full p-2",on:{"click":function($event){return _vm.openModal(_vm.data)}}},[(_vm.Svg)?_c('img',{staticClass:"h-5 w-5",attrs:{"src":require("../assets/svgs/trash.svg")}}):_vm._e()])])]),_c('div'),_c('div',{staticClass:"w-full"},[(_vm.Svg)?_c('div',{staticClass:"flex flex-wrap w-[90%]"},[_c('div',{staticClass:"move-image -mb-2",style:({ left: `${_vm.angleToPercentage(_vm.data?.trade_mgmt_number)}%` })},[_c('svg',{attrs:{"width":"37","height":"37","viewBox":"0 0 37 37","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0_340_11789)"}},[_c('path',{attrs:{"d":"M19.5896 25.7555C19.3241 26.021 18.971 26.1804 18.5963 26.204C18.2216 26.2276 17.8512 26.1136 17.5546 25.8835L17.4097 25.7555L8.15969 16.5055L8.03173 16.3606L7.94849 16.2419L7.86524 16.0939L7.83903 16.0384L7.7974 15.9351L7.74807 15.7686L7.73265 15.6869L7.71724 15.5944L7.71107 15.5065L7.70799 15.4156L7.71107 15.3246L7.71878 15.2352L7.73265 15.1427L7.74807 15.0625L7.7974 14.896L7.83903 14.7927L7.94694 14.5892L8.04715 14.4505L8.15969 14.3256L8.30461 14.1977L8.42332 14.1144L8.57132 14.0312L8.62682 14.005L8.73011 13.9633L8.89661 13.914L8.97832 13.8986L9.07082 13.8832L9.15869 13.877L9.24965 13.8739L27.7497 13.8739C29.0632 13.8739 29.7492 15.394 28.9568 16.376L28.8396 16.5055L19.5896 25.7555Z","fill":`${_vm.arrowColor}`}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_340_11789"}},[_c('rect',{attrs:{"width":"37","height":"37","fill":"white","transform":"translate(37 37) rotate(180)"}})])])])])]):_vm._e(),_c('div',{class:`flex w-100% rounded-b-3xl  progressLeft progressRight  justify-between ${
        !_vm.Svg ? 'mt-5' : ''
      }`},[_c('div',{staticClass:"h-3 progressLeft bg-[#3EAF3F]",class:_vm.data?.trade_mgmt_number <= 10 ? 'w-[25%]' : 'w-[15%]'}),_c('div',{staticClass:"h-3 bg-[#E1A325]",class:_vm.data?.trade_mgmt_number > 10 && _vm.data?.trade_mgmt_number <= 30
            ? 'w-[40%]'
            : 'w-[30%]'}),_c('div',{staticClass:"bg-[#E14942] progressRight h-3",class:_vm.data?.trade_mgmt_number > 30 ? 'w-[56%]' : 'w-[45%]'})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }