<template>
  <div v-if="!isProfileLoading" class="w-full h-full">
   
    <aside
      v-if="showSideBar?.includes(selectedPage)"
      id="logo-sidebar"
      :class="`${
        showSideBar?.includes(selectedPage) ? 'index' : 'z-50'
      } lg:w-full  sm:w-[40vw] second-sidebar w-[80vw] lg:h-[93vh] sm:h-[100vh] h-full bg-white dark:bg-[#24252A] border-r border-gray-200 dark:border-gray-700 overflow-hidden  `"
      aria-label="Sidebar"
    >
      <div class="h-full w-full ">
        <div class="h-[15vh] justify-center flex items-center">
          <div
            @click="handleGoBack"
            class="hover:underline dark:text-white/70 cursor-pointer h-fit px-2 flex space-x-3 items-center justify-center"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              xmlns="http://www.w3.org/2000/svg"
              class="fill-none stroke-black dark:stroke-white/70"
            >
              <path
                d="M12.6668 8.36426H3.3335"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.00016 13.0306L3.3335 8.36393L8.00016 3.69727"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span class="text-[14px]"
              >Back to
              <span
                class="text-btnPrimary dark:text-[#5174d6] font-bold text[15px]"
                >CondorCash</span
              >
            </span>
          </div>
        </div>

        <div>
          <ul
            class="space-y-2 font-medium px-3 overflow-y-auto"
            v-if="showSideBar[0] === selectedPage"
          >
            <li
              v-for="(item, index) in settingMenu"
              :key="index"
              @click="settingsClickHandler(item)"
            >
              <div
                :class="`flex items-center p-2  text-[15px] ${
                  item.name == 'Settings' ? 'bg-[#FFF9E3]' : ''
                } rounded-lg  ${
                  item.name === store.getters.getSettingsTab
                    ? 'bg-btnPrimary/10 text-btnPrimary dark:bg-[#87A1CC1A] dark:text-[#A9C9FF] boldText'
                    : `hover:bg-btnPrimary/10 cursor-pointer dark:text-white/50
                       text-black/60 dark:text-lightTextColor
                     dark:hover:bg-gray-700 ${
                       checkStatus(item)
                         ? 'bg-[#FFF9E3] text-[#C27500] dark:bg-[#FFC0411A] dark:text-[#FFC041] dark:border border-[#FFC041]'
                         : ''
                     }`
                }   group`"
              >
                <div v-if="item?.icon != null"><img :src="item.icon" /></div>
                <div v-if="item?.comp != null">
                  <component
                    :is="item?.comp.name"
                    :isActive="item.name === store.getters.getSettingsTab"
                  />
                </div>
                <a>
                  <span class="ms-3">{{ item.name }} </span>
                </a>
                <img
                  v-if="checkStatus(item)"
                  class="ml-2"
                  src="../../assets/svgs/warning.svg"
                />
              </div>
            </li>
          </ul>

          <div v-if="showSideBar[1] === selectedPage">
            <ul
              class="space-y-2 font-medium px-6 overflow-y-auto"
              v-if="tradeData?.length > 0"
            >
              <div id="historyElement1">
                <p class="dark:text-white/70">Active Trades</p>
              </div>
              <li
                v-for="(item, index) in tradeData"
                :key="index"
                @click="tradeHistory(item)"
              >
                <div
                  :class="`flex items-center p-2 rounded-lg text-[15px]   
                ${
                  item.id === store.getters.getTradeHistoryById
                    ? 'bg-btnPrimary/10 text-btnPrimary dark:text-[#A9C9FF] dark:bg-[#87A1CC1A]'
                    : 'hover:bg-btnPrimary/10 cursor-pointer text-black/60 dark:text-white/50 '
                } group`"
                >
                  <EditSvg
                    :isActive="item.id === store.getters.getTradeHistoryById"
                  />
                  <a>
                    <span class="ms-3">{{ item.reference_name }}</span>
                  </a>
                  <!-- <img v-if="checkStatus(item)" class="ml-2" src="../../assets/svgs/warning.svg"/> -->
                </div>
              </li>
            </ul>
            <div
              v-else
              class="w-full h-full flex justify-center items-center px-6"
            >
              <span
                class="text-[14px] text-lightTextColor dark:text-darkTextColor"
                >No trades available</span
              >
            </div>
          </div>
        </div>
      </div>
    </aside>
    
    <aside
      v-else
      id="logo-sidebar"
      :class="` ${
        showSideBar?.includes(selectedPage) ? 'z-50' : 'index'
      } lg:w-full sm:w-[40vw] first-sidebar w-[80vw] lg:h-[93vh] sm:h-[100vh] h-full bg-white dark:bg-[#24252A] border-r border-gray-200 dark:border-gray-700 overflow-hidden sm:relative`"
      aria-label="Sidebar"
    >
      <div class="h-full relative ">
        <div class="px-4 flex  justify-between items-center h-[15vh]">
          <div
            class="flex items-center lg:justify-center justify-between w-full"
          >
            <img
              v-if="store.getters.getTheme"
              src="../../assets/svgs/sidebar/CCDarkLogo.svg"
              width="130"
            />
            <img
              v-else
              src="../../assets/svgs/sidebar/CCLightLogo.svg"
              width="130"
            />
            <!-- 
            <p class="sm:block hidden text-[10px] text-[#B0B0B0] mt-2 mr-2">
              V.0.0.1
            </p> -->
            <div
              @click="toggleCloseSidebar"
              class="rounded-full lg:hidden cursor-pointer flex justify-center items-center"
            >
              <CrossIconSvg />
            </div>
          </div>
        </div>
        <!--Dashboard list item rendering over -->
        <ul class="space-y-2 font-medium px-4  overflow-y-auto">
          <!-- && store.getters.getUserDetails.is_profile_complete===false -->
          <li
            v-for="(item, index) in menuItems"
            :key="index"
            @click="menuItemValue(item)"
            :class="`${
              userDetails?.is_profile_complete === false
                ? `${
                    item.name == 'Active Trades' ||
                    item.name == 'Trade History' ||
                    item.name == 'Credit & Returns'
                      ? 'pointer-events-none'
                      : ''
                  } `
                : ''
            } `"
          >
            <div
              v-if="item.name == 'Send Feedback'"
              @click="handleContactUs"
              :class="`flex items-center p-2 space-x-3 rounded-lg text-[14px]
              
               ${
                 item.name === selectedMenu
                   ? 'bg-btnPrimary/10 dark:bg-[#87a1cc1a]  dark:text-blue-300 text-btnPrimary font-[600]'
                   : `hover:bg-[#F6F6F6] text-[#9a9a9a] dark:text-white/50 cursor-pointer font-[400]   dark:hover:bg-gray-700`
               }`"
            >
              <div v-if="item?.icon != null">
                <img :src="item.icon" />
              </div>
              <div v-if="item?.comp != null">
                <component
                  :is="item?.comp.name"
                  :isActive="item.name === selectedMenu"
                />
              </div>
              <span>{{ item.name }}</span>
            </div>

            <router-link v-else :to="item.route" :id="item.id">
              <div
                v-if="item.name == 'Settings'"
                class="border-t mb-2 border-gray-200 dark:border-[#888888]/30 flex items-center space-x-2"
              ></div>
              <!-- :id="`${item.name==='Active Trades' ? 'dashboardElement5':'tradeHistory'}`" -->
              <div 
                :id="getDivId(item) "
                :class="`flex  items-center p-2  text-[14px]  ${
                  item.name == 'Settings' && !isProfileComplete
                    ? 'bg-[#FFF9E3] text-[#C27500] dark:text-yellowDark  dark:bg-[#ffc0411a] border border-[#FFC041]'
                    : 'text-black/60 '
                } rounded-lg  ${
                  item.name === selectedMenu
                    ? 'bg-btnPrimary/10 dark:bg-[#87a1cc1a]  dark:text-blue-300 text-btnPrimary font-[600]'
                    : `hover:bg-[#F6F6F6] text-[#9a9a9a] dark:text-white/50 cursor-pointer font-[400]   dark:hover:bg-gray-700`
                }   group ${
                  item.name === 'Send Feedback' || item.name === 'Help'
                    ? 'text-gray-400'
                    : ''
                }`"
              >
                <div v-if="item?.icon != null">
                  <img :src="item.icon" />
                </div>
                <div v-if="item?.comp != null">
                  <component
                    :is="item?.comp.name"
                    :isActive="item.name === selectedMenu"
                  />
                </div>
                <div class="flex items-center justify-between w-full">
                  <a>
                    <span
                      v-if="
                        item.name !== 'Dashboard' &&
                        item.name !== 'Send Feedback' &&
                        item.name !== 'Help' &&
                        item.name !== 'Trade Risk Calculator' &&
                        item.name !== 'Settings' &&
                        !isProfileComplete
                      "
                      :class="`ms-3 text-gray-400`"
                    >
                      {{ item.name }}
                    </span>
                    <span v-else class="ms-3">
                      {{ item.name }}
                    </span>
                  </a>
                  <div
                    v-if="
                      item.name !== 'Dashboard' &&
                      item.name !== 'Send Feedback' &&
                      item.name !== 'Help' &&
                      item.name !== 'Trade Risk Calculator' &&
                      item.name !== 'Settings'
                    "
                  >
                    <div v-if="!isProfileComplete">
                      <img src="../../assets/svgs/lock.svg" />
                    </div>
                  </div>
                  <img
                    v-if="item.name === 'Settings' && !isProfileComplete"
                    src="../../assets/svgs/warning.svg"
                  />
                </div>
              </div>
              <div
                v-if="item.name == 'Settings'"
                class="border-t mt-2 border-gray-200 dark:border-[#888888]/30"
              ></div>
            </router-link>
          </li>
        </ul>

        <!-- <div class="w-full px-5 space-y-4 absolute bottom-4"> -->

          <!-- last element of dashboard -->
        <div
          class="w-full px-5 flex flex-col  sm:space-y-4 space-y-6 absolute sm:bottom-4 -bg-slate-400 bottom-16"
        >
          <div class="w-full">
            <CustomButton
              title="Upgrade"
              buttonType="primary"
              :customStyle="{
                '!py-2 dark:text-blue-200': true,
                hidden:
                  !isProfileComplete || (isPlatinumPlan && isProfileComplete),
              }"
              @onClick="goToSettings"
            />
          </div>
          <div class="w-full" @click.stop>
            <CustomButton
              title="View Plans"
              buttonType="secondary"
              customStyle="!py-2  dark:bg-lightTextColor/40 dark:hover:bg-lightTextColor/20"
              @onClick="modalOpenHandler"
            />
          </div>
          <div class="flex justify-between text-[15px] items-center">
            <div class="flex items-center space-x-3">
              <div
                v-if="userDetails?.firstname"
                class="w-7 h-7 rounded-full bg-btnSecondary dark:bg-compBgDark/20 flex justify-center items-center"
              >
                <div v-if="userDetails?.profile_picture">
                  <img
                    :src="userDetails?.profile_picture"
                    class="w-7 h-7 rounded-full"
                  />
                </div>

                <p class="font-bold text-[13px] dark:text-white/70" v-else>
                  {{
                    userDetails?.firstname?.length
                      ? userDetails?.firstname[0]
                      : ""
                  }}
                </p>
              </div>

              <p class="text-[13px] dark:text-white/70">
                {{ userDetails?.firstname }}
              </p>
            </div>

            <div @click="toggleLogOut">
              <svg
                class="relative cursor-pointer lg:hidden dark:fill-white/70 dark:stroke-white/70 h-5 w-5"
                viewBox="0 0 25 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 5.625C11 5.32833 11.088 5.03832 11.2528 4.79165C11.4176 4.54497 11.6519 4.35271 11.926 4.23918C12.2001 4.12565 12.5017 4.09594 12.7926 4.15382C13.0836 4.2117 13.3509 4.35456 13.5607 4.56434C13.7704 4.77412 13.9133 5.04139 13.9712 5.33237C14.0291 5.62334 13.9994 5.92494 13.8858 6.19903C13.7723 6.47311 13.58 6.70738 13.3334 6.87221C13.0867 7.03703 12.7967 7.125 12.5 7.125C12.1022 7.125 11.7206 6.96697 11.4393 6.68566C11.158 6.40436 11 6.02283 11 5.625ZM12.5 10.5C12.2033 10.5 11.9133 10.588 11.6666 10.7528C11.42 10.9176 11.2277 11.1519 11.1142 11.426C11.0006 11.7001 10.9709 12.0017 11.0288 12.2926C11.0867 12.5836 11.2296 12.8509 11.4393 13.0607C11.6491 13.2704 11.9164 13.4133 12.2074 13.4712C12.4983 13.5291 12.7999 13.4994 13.074 13.3858C13.3481 13.2723 13.5824 13.08 13.7472 12.8334C13.912 12.5867 14 12.2967 14 12C14 11.6022 13.842 11.2206 13.5607 10.9393C13.2794 10.658 12.8978 10.5 12.5 10.5ZM12.5 16.875C12.2033 16.875 11.9133 16.963 11.6666 17.1278C11.42 17.2926 11.2277 17.5269 11.1142 17.801C11.0006 18.0751 10.9709 18.3767 11.0288 18.6676C11.0867 18.9586 11.2296 19.2259 11.4393 19.4357C11.6491 19.6454 11.9164 19.7883 12.2074 19.8462C12.4983 19.9041 12.7999 19.8744 13.074 19.7608C13.3481 19.6473 13.5824 19.455 13.7472 19.2084C13.912 18.9617 14 18.6717 14 18.375C14 17.9772 13.842 17.5956 13.5607 17.3143C13.2794 17.033 12.8978 16.875 12.5 16.875Z"
                />
              </svg>
            </div>
            <div
              v-if="openLogOut"
              class="lg:hidden flex items-center space-x-3 justify-end sm:h-10 h-12 mt-0.5 w-full sm:py-1 py-3 absolute -left-5 sm:bottom-[-4px] bottom-18 right-0 index"
            >
              <div @click="themeHandler">
                <img
                  src="../../assets/svgs/sun.svg"
                  class="h-5 w-5"
                  v-if="store.state.theme"
                />
                <img src="../../assets/svgs/moon.svg" class="h-5 w-5" v-else />
              </div>

              <div @click="logoutHandler"
                class="flex h-7 w-7 items-center justify-center rounded-full"
              >
                <img src="../../assets/svgs/logout.svg" class="w-4 h-4" />
              </div>

              <div @click="toggleLogOut">
                <svg
                  class="relative cursor-pointer lg:hidden dark:fill-white/70 dark:stroke-white/70 h-5 w-5"
                  viewBox="0 0 25 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 5.625C11 5.32833 11.088 5.03832 11.2528 4.79165C11.4176 4.54497 11.6519 4.35271 11.926 4.23918C12.2001 4.12565 12.5017 4.09594 12.7926 4.15382C13.0836 4.2117 13.3509 4.35456 13.5607 4.56434C13.7704 4.77412 13.9133 5.04139 13.9712 5.33237C14.0291 5.62334 13.9994 5.92494 13.8858 6.19903C13.7723 6.47311 13.58 6.70738 13.3334 6.87221C13.0867 7.03703 12.7967 7.125 12.5 7.125C12.1022 7.125 11.7206 6.96697 11.4393 6.68566C11.158 6.40436 11 6.02283 11 5.625ZM12.5 10.5C12.2033 10.5 11.9133 10.588 11.6666 10.7528C11.42 10.9176 11.2277 11.1519 11.1142 11.426C11.0006 11.7001 10.9709 12.0017 11.0288 12.2926C11.0867 12.5836 11.2296 12.8509 11.4393 13.0607C11.6491 13.2704 11.9164 13.4133 12.2074 13.4712C12.4983 13.5291 12.7999 13.4994 13.074 13.3858C13.3481 13.2723 13.5824 13.08 13.7472 12.8334C13.912 12.5867 14 12.2967 14 12C14 11.6022 13.842 11.2206 13.5607 10.9393C13.2794 10.658 12.8978 10.5 12.5 10.5ZM12.5 16.875C12.2033 16.875 11.9133 16.963 11.6666 17.1278C11.42 17.2926 11.2277 17.5269 11.1142 17.801C11.0006 18.0751 10.9709 18.3767 11.0288 18.6676C11.0867 18.9586 11.2296 19.2259 11.4393 19.4357C11.6491 19.6454 11.9164 19.7883 12.2074 19.8462C12.4983 19.9041 12.7999 19.8744 13.074 19.7608C13.3481 19.6473 13.5824 19.455 13.7472 19.2084C13.912 18.9617 14 18.6717 14 18.375C14 17.9772 13.842 17.5956 13.5607 17.3143C13.2794 17.033 12.8978 16.875 12.5 16.875Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <!-- !store.getters.getToggleSettings -->
  </div>
</template>

<script>
import SettingSvg from "@/assets/svgs/sidebar/settings.vue";
import CustomButton from "../../components/SharedComponents/CustomButton.vue";
import router from "@/router";
import store, {
  getTradeHistory,
  getUserDetailsFromStore,
  updateSideBarByUserDetails,
  getTourNextStep,
} from "../../store";
import DashboardSvg from "@/assets/svgs/sidebar/DashboardSvg.vue";
import ActiveTradeSvg from "@/assets/svgs/sidebar/ActiveTradeSvg.vue";
import TradeHistorySvg from "@/assets/svgs/sidebar/TradeHistorySvg.vue";
import TradeRiskCalculatorSvg from "@/assets/svgs/sidebar/TradeRiskCalculatorSvg.vue";
import CreditReturnsSvg from "@/assets/svgs/sidebar/CreditReturnsSvg.vue";
import HelpSvg from "../../assets/svgs/sidebar/HelpSvg.vue";
import FeedBackSvg from "../../assets/svgs/sidebar/FeedBackSvg.vue";
import ProfileSvg from "../../assets/svgs/sidebar/ProfileSvg.vue";
import AddressBillingSvg from "../../assets/svgs/sidebar/AddressBillingSvg.vue";
import SubscriptionPlansSvg from "../../assets/svgs/sidebar/SubscriptionPlansSvg.vue";
import NotificationsSvg from "../../assets/svgs/sidebar/NotificationsSvg.vue";
import userApi from "@/services/userApi";
import EditSvg from "../../assets/svgs/sidebar/editSvg.vue";
import CrossIconSvg from "../../assets/svgs/CrossIconSvg.vue";
import BaseModal from "../../components/SharedComponents/BaseModal.vue";
import tourService from "@/services/tourService";

import { mapState } from "vuex";

export default {
  name: "SideBar",
  props: {
    // closeMenu : Boolean
  },
  setup() {
    return {
      store,
    };
  },
  components: {
    CustomButton,
    DashboardSvg,
    ActiveTradeSvg,
    TradeHistorySvg,
    TradeRiskCalculatorSvg,
    CreditReturnsSvg,
    FeedBackSvg,
    HelpSvg,
    ProfileSvg,
    AddressBillingSvg,
    SubscriptionPlansSvg,
    NotificationsSvg,
    SettingSvg,
    EditSvg,
    CrossIconSvg,
    BaseModal,
  },
  data() {
    return {
      // userProfile: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      selectedPage: null,
      openLogOut: false,
      showSideBar: ["Settings", "Trade History"],
      toggleFeatureAvailable: false,
      menuItems: [
        {
          name: "Dashboard",
          icon: null,
          comp: DashboardSvg,
          route: "/members-area",
          id: "members-area",
        },
        {
          name: "Active Trades",
          icon: null,
          comp: ActiveTradeSvg,
          route: "/active-trades",
          id: "active-trades",
        },
        {
          name: "Trade History",
          icon: null,
          comp: TradeHistorySvg,
          route: "/trade-history",
          id: "trade-history",
        },
        {
          name: "Trade Risk Calculator",
          icon: null,

          comp: TradeRiskCalculatorSvg,
          route: "/trade-risk-calculator",
          id: "trade-risk-calculator",
        },
        {
          name: "Credit & Returns",
          icon: null,
          comp: CreditReturnsSvg,
          route: "/credit-returns",
          id: "credit-returns",
        },
        {
          name: "Settings",
          comp: SettingSvg,
          icon: null,
          route: "/settings",
          id: "settings",
        },
        {
          name: "Send Feedback",
          icon: null,
          comp: FeedBackSvg,
          route: "/send-feedback",
          id: "send-feedback",
        },
        {
          name: "Help",
          icon: null,
          comp: HelpSvg,
          route: "/help",
          id: "help",
        },
      ],
      selectedMenu: "Dashboard",
      settingMenu: [
        {
          name: "Profile",
          icon: null,
          comp: ProfileSvg,
          storeKey: ["profile"],
        },
        {
          name: "Address & Billing",
          icon: null,
          comp: AddressBillingSvg,
          storeKey: ["address", "billing"],
        },
        {
          name: "Subscription Plans",
          icon: null,
          comp: SubscriptionPlansSvg,
          storeKey: ["plan"],
        },
        {
          name: "Notifications",
          icon: null,
          comp: NotificationsSvg,
          storeKey: [],
        },
      ],

      TradeHistory: [
        { name: "Trade 1", comp: EditSvg, icon: null },
        { name: "Trade 2", comp: EditSvg, icon: null },
      ],
    };
  },
  methods: {
    getDivId(item) {
      if (item.name === "Active Trades") {
        return "dashboardElement5";
      } else if (item.name == "Trade Risk Calculator") {
        return "dashboardElement6";
      } else if (item.name === "Credit & Returns") {
        return "dashboardElement7";
      } else if (item.name === "Trade History") {
        return "dashboardElement8";
      }
    },
    startDashboardTour() {
      if(this.isMobileDevice() && this.store.getters.getSidebarToggle){
        const steps = [
          {
            element: "#dashboardElement5",
            intro: "this is step number 1",
          },
          {
            element: "#dashboardElement15",
            intro:
              "Here you can monitor your trades’ risk management number, with the help of the CondorCash proprietary risk management tool.",
          },
          {
            element: document.querySelector("#dashboardElement8"),
            intro: "It shows the trade history of your current Active trades",
          },
          {
            element: document.querySelector("#dashboardElement6"),
            intro:
              "Trade risk calculator is our proprietary tool, it tells you the status of your trade.",
          },
          {
            element: document.querySelector("#dashboardElement7"),
            intro: "Calculate credits and returns using this section.",
          },

        // Add more steps as needed
      ];
      tourService.startTour(steps, this.onTourEnd);
      }
    },

    async getSiteSettings() {
      try {
        const response = await userApi.getSiteSettings();

        if (response?.data?.status) {
          console.log("response Get", response.data.data);
          store.commit("SET_SITE_SETTING", response.data.data);
        }
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    handleContactUs() {
      this.$emit("toggleContactModal", true);
    },
    tradeHistory(item) {
      getTradeHistory(item.id);

      store.commit("SET_TRADE_HISTORY", false); // added this line for closing the trade modal
      store.commit("SET_SIDEBAR_TOGGLE", false);
    },
    modalOpenHandler() {
      store.commit("SET_SIDEBAR_TOGGLE", false);
      this.$emit("toggleModal", true);
      console.log("runiiiiiiiiiig")
          
    
    },
    logoutHandler() {
      localStorage.clear();
      store.commit("SET_TOGGLE_THEME", false);
      store.commit("SET_SIDEBAR_TOGGLE", false);
      store.commit("RESET_STATE");
      router.push("/login");
    },

    checkStatus(item) {
      let storeKey = item.storeKey;
      console.log("storeKey", storeKey);
      if (storeKey.length) {
        // let keys = storeKey.split("|")
        let storeRef = true;
        for (let key of storeKey) {
          storeRef = storeRef && this.userDetails?.profile_status?.[key];
        }
        if (!this.isProfileComplete && !storeRef) {
          return true;
        }
      }

      return false;
    },

    downGradeProfile() {
      userApi.resetProfile({}).then((d) => {
        console.log(d);
        // location.reload();
        router.push("/login");
      });
    },
    toggleLogOut() {
      this.openLogOut = !this.openLogOut;
    },
    themeHandler() {
      store.commit("SET_TOGGLE_THEME", !store.getters.getTheme);
      store.commit("SET_SIDEBAR_TOGGLE", false);
    },
    menuItemValue(value) {
      if (value.name === "Send Feedback") {
        return;
      } else {
        store.commit("SET_NAVBAR_LABEL", value.name);
        this.toggleCloseSidebar();
        this.selectedMenu = value.name;
        let userDetails = this.userDetails;

        if (value.name === "Settings") {
          updateSideBarByUserDetails(userDetails);
        } else if (userDetails?.is_profile_complete) {
          this.selectedMenu = value.name;
        } else {
          return;
        }
      }
    },
    goToSettings() {
      store.commit("SET_TOGGLE_SETTINGS", true);
      router.push("/settings");
      this.selectedPage = "Settings";
      store.commit("SET_NAVBAR_LABEL", "Subscription Plans");
      store.commit("SET_SETTINGS_TAB", "Subscription Plans");
      store.commit("SET_SIDEBAR_TOGGLE", false);
    },
    settingsClickHandler(item) {
      console.log("settingsClickHandler", item);
      if (!this.isProfileComplete && item.name === "Notifications") {
        store.commit("SET_SIDEBAR_TOGGLE", false);
        return;
      } else {
        store.commit("SET_SIDEBAR_TOGGLE", false);
        let status = this.checkStatus(item);
        if (status) {
          store.commit("SET_SIDEBAR_TOGGLE", false);
          let cStatus = true;
          for (let singleKey in item.storeKey) {
            cStatus = cStatus && this.userDetails.next_step == singleKey;
          }
          if (!cStatus) {
            store.commit("SET_SIDEBAR_TOGGLE", false);
            return;
          }
        }
        console.log("status", item.name);
        store.commit("SET_NAVBAR_LABEL", item.name);
        store.commit("SET_SETTINGS_TAB", item.name);
        store.commit("SET_SIDEBAR_TOGGLE", false);
        store.commit("SET_TOGGLE_SETTINGS", false);
      }
    },

    handleGoBack() {
      this.$router.go(-1);
      store.commit("SET_TOGGLE_SETTINGS", false);
      store.commit("SET_SIDEBAR_TOGGLE", false);

      // this.selectedMenu = "DashBoard";
    },
    toggleCloseSidebar() {
      store.commit("SET_SIDEBAR_TOGGLE", false);
    },
    isMobileDevice(){
      return (window.innerWidth <= 780)
    }
  },
  computed: {
    ...mapState(["tourNextStep"]),

    userDetails() {
      return getUserDetailsFromStore();
    },
    tourNext() {
      return this.tourNextStep;
    },
    getTourNext() {
      return getTourNextStep();
    },
    isProfileComplete() {
      // return false
      let user = this.userDetails;
      console.log("userDetails", user);
      return user?.is_profile_complete ?? false;
    },
    isPlatinumPlan() {
      let user = this.userDetails;
      return (user?.subscription === 8 || user?.subscription === 9) ?? false;
    },
    isProfileLoading() {
      return store.getters.isProfileLoading;
    },
    tradeData() {
      return store.getters.getTradeData;
    },
    getTradeHistoryById() {
      return store.getters.getTradeHistoryById;
    },
    // ...mapState([this.selectedMenu])
  },
  mounted() {
    console.log("tradeData==>", store.getters?.getTradeData)
    this.getSiteSettings();
    console.log("store.getters.getNaBarValue", store.getters.getNaBarValue);
    if (this.$route?.name) {
      this.selectedMenu = this.$route?.name;
      this.selectedPage = this.$route?.name;
    }
    console.log("checking", document.querySelector("#dashboardElement5"));
    console.log("checking", document.querySelector("#dashboardElement6"));
    console.log("checking", document.querySelector("#dashboardElement7"));

    console.log("checking", document.querySelector("#step"));
    // this.startDashboardTour()
    // setTimeout(() => {
    //   this.startDashboardTour();
    // }, 1000);
  },
  watch: {
    // '$store.getters.getTourNextStep'(newVal, oldVal) {
    //   console.log("tourStep changed", newVal, oldVal);
    //   // If you want to react to changes in getTourNext as well, you can call the method here
    //   // this.handleTourNextChange();
    // },
    $route: function (newPath) {
      this.selectedPage = newPath.name;
      if (this.selectedMenu != newPath.name) {
        this.selectedMenu = newPath.name;
        if (newPath.name === "Settings") {
          store.commit("SET_SIDEBAR_TOGGLE", false);
          store.commit("SET_TOGGLE_SETTINGS", false);
        }
      }
    },
    tradeData: {
      immediate: true, // Run the handler immediately
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          
          getTradeHistory(newValue[0]?.id);
          store.commit("SET_TRADE_HISTORY_BY_ID", newValue[0]?.id);
        }
      },
    },

    // getTradeHistoryById(n,o){
    //   console.log("getTradeHistoryById",n,o)
    //   if(n && n != o){
    //     getTradeHistory(n)
    //   }
    // }
  },
};
</script>

<style scoped>
.second-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  left: -240px;
  width: 240px;
  transition: transform 0.3s ease !important;
  transform: translateX(100%) !important;
}

.index {
  z-index: 1000 !important;
}
</style>
