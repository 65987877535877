<template>
  <div
    :class="`lg:h-screen -sm:h-[92.5vh]  h-full  w-full fixed overflow-hidden  ${
      store.getters.getTheme ? 'dark bg-[#2D2E35]' : 'bg-[#F3F3F3]'
    }`"
  >
    <div
      class="h-full w-full"
      v-click-outside="isMobileOrTabletDevice ? null : handleCloseOutside"
    >
      <div v-if="isProtected" class="flex flex-col h-full w-full relative">
        <div class="flex h-[100vh] w-full">
          <!-- sidebar -->
          <div class="lg:flex hidden w-[250px] h-full">
            <SideBar
              @toggleModal="toggleModalHandler"
              @toggleContactModal="handleContactSideModal"
            />
          </div>
          <div
            class="w-[330px] lg:hidden flex -bg-slate-300 !h-[100vh]"
            v-if="store.getters.getSidebarToggle"
          >
            <div
              class="w-full h-full absolute index bg-[#00000050] backdrop-blur-sm"
            >
              <SideBar @toggleModal="toggleModalHandler" class="h-full" />
            </div>
          </div>
          <!-- sidebar -->

          <!-- Header & page -->
          <div class="lg:w-11/12 w-full h-full sm:pb-20 lg:pb-0 -bg-orange-400 relative">
            <div
              class="w-[100vw] sm:w-full lg:h-[8vh] h-[6vh] backdrop-blur-sm absolute z-50"
            >
              <Navbar @toggleModal="toggleBrookerHandler" />
            </div>
            <div
              class="w-full flex overflow-x-hidden justify-center h-full -bg-red-500 md:px-8 --px-3 overflow-y-auto scrollbar sm:py-4"
            >
              <div class="lg:w-[85%] md:[90%] sm:w-[93%] w-full h-full pt-16">
                <router-view></router-view>
              </div>
            </div>
          </div>
          <!-- Header & page -->
        </div>
        <!-- Footer -->
        <div
          class="lg:h-[7vh] sm:h-[13vh] h-[12.5vh] absolute z-50 bottom-0 backdrop-blur-sm w-full"
        >
          <Footer @toggleModal="handleContactModal" />
        </div>
        <!-- Footer -->
      </div>
      <div class="-bg-red-500 -bg-[#F3F3F3] dark:bg-[#F3F3F3]" v-else>
        <div class="lg:h-[93vh] sm:h-[85.5vh] h-[86.5vh] -bg-yellow-500 w-full">
          <router-view></router-view>
        </div>
        <div
          class="md:h-[7vh] sm:!h-[10vh] h-[12vh] lg:block absolute z-10 bottom-0 w-full"
        >
          <Footer @toggleModal="handleContactModal" :isProtected="isProtected" />
        </div>
      </div>
    </div>
    <ActiveTradeModal
      v-if="store.getters.getTradeModal"
      @onClose="closeModal"
    />

    <DeleteTradeModal
      v-if="store.getters.getDeleteTrade"
      @onClose="closeDeleteModal"
    />

    <ContactModal
      v-if="toggleContactModal"
      @onClose="handleCloseContact"
      :title="title"
    />
    
    <BrookerModal
      v-if="store.getters.getBrookerModal"
      @onClose="handleCloseBrooker"
    />

    

    <BaseModal v-if="togglePlansModal">
      <div class="h-full lg:w-[55vw] sm:w-[80vw] w-full overflow-hidden">
        <div class="w-full flex justify-end">
          <div
            class="w-fit flex items-center cursor-pointer p-1 mr-1 mt-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800"
            @click="togglePlansModal = false"
          >
            <CrossIconSvg stroke="#8a8a8a" />
          </div>
        </div>
        <div class="h-full w-full flex items-center justify-center">
          <ViewPlans />
          <!-- <div class="h-full w-full rounded-3xl sm:!p-8 p-4">
            <div
              class="h-full w-full flex items-center flex-row justify-around sm:space-x-2 space-x-8"
            >
              <div
                class="h-full w-full flex flex-col -whitespace-nowrap dark:text-white"
              >
                <span class="sm:text-[22px] text-[18px]"
                  >This page is under deve</span
                >
                <span class="sm:text-[22px] text-[18px] font-bold"
                  >It will be available soon.</span
                >
              </div>
              <div class="h-full w-2/6 justify-end flex">
                <ComingSoon />
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="w-full flex items-center justify-end px-4 pb-2">
          <div>
            <CustomButton
              title="OK"
              @onClick="togglePlansModal = false"
              buttonType="primary"
              customStyle="!px-6 !py-1.5"
            />
          </div>
        </div> -->
      </div>
    </BaseModal>
  </div>
</template>

<script>
import Footer from "./components/footer";
import Navbar from "./components/NavBar";
import SideBar from "./components/SideBar";
import tradeApi from "@/services/tradeApi";
import ViewPlans from "@/components/ViewPlans";
import userApi from "@/services/userApi";
import CrossIconSvg from "./assets/svgs/CrossIconSvg.vue";
import store, { getUserDetailsFromStore } from "@/store";
import ActiveTradeModal from "@/components/Modal/activeTradeModal/index.vue";
import DeleteTradeModal from "@/components/Modal/deleteTradeModal";
import BaseModal from "./components/SharedComponents/BaseModal.vue";
import ContactModal from "./components/Modal/ContactModal";
import BrookerModal from "./components/Modal/BrookerModal"
import "./index.css";
import vClickOutside from "vue-click-outside";
// import axios from "@/api/axios";
// import { SUBSCRIPTION_PLANS } from "./api/apiRoutes";

export default {
  name: "App",
  directives: {
    "click-outside": vClickOutside,
  },
  computed: {
    store() {
      return store;
    },
    userDetails() {
      return getUserDetailsFromStore();
    },
    isProfileLoading() {
      return store.state.isProfileLoading;
    },
    isProfileComplete() {
      let user = this.userDetails;
      return user?.is_profile_complete ?? false;
    },
  },
  data() {
    return {
      isProtected: false,
      isAuthenticated: localStorage.getItem("token"),
      toggleContactModal: false,
      togglePlansModal: false,
      toggleBrookerModal: false,
      title: "Contact Us",
      isMobileOrTabletDevice:
        /Mobile|Android|iOS|iPhone|iPad|iPod|Windows Phone|Tablet|Kindle/i.test(
          navigator.userAgent
        ),
    };
  },
  methods: {
    handleCloseContact() {
      this.toggleContactModal = false;
    },
    handleCloseBrooker() {
      this.toggleBrookerModal = false;
    },
    handleCloseOutside() {
      this.toggleContactModal = false;
      this.togglePlansModal = false;
      // store.commit("SET_TRADE_MODAL", false);
      store.commit("SET_DELETE_TRADE", false);
    },
    handleContactModal() {
      this.title = "Contact Us";
      this.toggleContactModal = true;
    },
    handleContactSideModal() {
      this.title = "Send Feedback";
      this.toggleContactModal = true;
    },
    closeModal() {
      store.commit("SET_TRADE_MODAL", false);
    },
    closeDeleteModal() {
      store.commit("SET_DELETE_TRADE", false);
    },
    toggleModalHandler(val) {
      console.log("toggleModalHandler", val);
      this.togglePlansModal = val;
    },
    toggleBrookerHandler(val) {
      console.log("toggleBrookerHandler", val);
      this.toggleBrookerModal = val;
    },
    closeFunctin(){
      this.toggleBrookerModal = false
    },
    loadingTradingData: function () {
      if (this.isAuthenticated) {
        tradeApi
          .tradeDetails()
          .then((d) => {
            console.log("D.dta 1", d.data);
            store.commit("SET_TRADE_DATA_LIST", d.data.trades);
            // this.tradeData = d.data.trades
            this.tradeLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.tradeLoading = false;
          });
      }
    },
    async getSiteSettings() {
      // const getPrice = await axios.get(SUBSCRIPTION_PLANS)//await axios.get(SUBSCRIPTION_PLANS)
      const getPrice = await userApi.getSubscriptionPlans()//await axios.get(SUBSCRIPTION_PLANS)
      store.commit("SET_SUBSCRIPTION_PRICES", getPrice?.data?.list);
      console.log('prices', getPrice?.data?.list)
      if (this.isAuthenticated) {
        // this.isLoading = true;
        try {
          //getPackages
          
          const response = await userApi.getSiteSettings();

          if (response?.data?.status) {
            console.log("response Get", response.data.data);
            store.commit("SET_SITE_SETTING", response.data.data);
          }
        } catch (err) {
          console.log(err);
          this.isLoading = false;
        }
      }
    },
    validatePath() {
      const currentPath = this.$route.path;
      if (this.isProfileComplete) {
        console.log("Here");
        return;
      } else {
        console.log("currentPath", this.isProfileComplete);
        if (
          currentPath === "/active-trades" ||
          currentPath === "/trade-history" ||
          currentPath === "/credit-returns"
        ) {
          console.log("Redirecting from", currentPath, "to /");
          this.$router.push("/");
        }
      }
    },
  },
  components: {
    ActiveTradeModal,
    // ComingSoon,
    Footer,
    Navbar,
    SideBar,
    DeleteTradeModal,
    ViewPlans,
    BaseModal,
    // CustomButton,
    CrossIconSvg,
    ContactModal,
    BrookerModal
  },

  mounted() {
    if (this.isAuthenticated) {
      this.isProtected = true;
      console.log("isProtected ap", this.isProtected);
      store.commit("SET_PROFILE_LOADING", true);
      userApi
        .userDetails()
        .then((resp) => {
          console.log("login resp", resp);

          store.commit("SET_USER_DETAIL", resp.data.user_detail);
          store.commit("SET_PROFILE_LOADING", false);
          this.validatePath();
        })
        .catch((e) => {
          console.log(e);
          store.commit("SET_PROFILE_LOADING", false);
        });
      this.getSiteSettings();
    }
    this.loadingTradingData();
  },
  watch: {
    $route: function (newPath) {
      if (newPath?.meta.requiresAuth) {
        // Do something when the URL changes to /members-area
        console.log("URL changed to /members-area", newPath);
        const token = localStorage.getItem("token");
        console.log("token==>", token);
        if (token) {
          this.getSiteSettings()
          this.isProtected = true;
          console.log("isProtected app", this.isProtected);
        } else {
          this.isProtected = false;
        }
      } else {
        this.isProtected = false;
      }
    },
    isProfileLoading: function (n, o) {
      console.log("isProfileLoading", n, o);
      if (n) {
        this.loadingTradingData();
        store.commit("SET_PROFILE_LOADING", false);
      }
    },
  },
};
</script>

<style scoped>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
.index {
  z-index: 1000 !important;
}
</style>
